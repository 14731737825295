<template>
  <div>

    <select class="form-control" name="categoria" @change="onChangeValor()" v-model="categoria_idSelected">
      <option v-for="item in registros" :value="item.id" :key="item.id">
        {{ item.categoria }}
      </option>
    </select>

  </div>
</template>
<script>
import httpService from "@/services/httpService"

export default {
  props: {
    categoriaId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      registro_id: '',
      categoria_idSelected: '',
      registros: [],
      isLoading: false
    }
  },
  watch: {
    /* Observar cambios en el valor del PROPS y cuando ya tenga busco los datos del registro */
    categoriaId() {
      this.categoria_idSelected = this.categoriaId
      return this.categoria_idSelected
    }
  },
  methods: {
    async getRegistros() {
      this.isLoading = true
      const url = "eco-categorias"
      let response = await httpService.get(url)
      const respuesta = response.data
      this.registros = respuesta.registros
      this.isLoading = false
    },
    onChangeValor() {
      this.registro_id = this.categoria_idSelected
      this.$emit("ActualizandoId", this.categoria_idSelected)
    }
  },
  mounted() {
    this.$emit("ActualizandoId", this.registro_id)
    this.getRegistros()
  }
}
</script>