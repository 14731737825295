<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <div class="form-group">
              <label>
                Etiquetas:
              </label>
              <validation-provider
                  name="Etiquetas"
                  :rules="{ required: true }"
                  :customMessages="{
                  required: 'Es un campo requerido'
                }"
                  v-slot="validationContext"
              >
                <selectEtiqueta @ActualizandoId="setEtiqueta"/>
                <input type="hidden" v-model="registro.eco_etiqueta_id"/>

                <span style="color: red; font-size: small">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </CCol>
        </CRow>

      </CCardBody>
    </CCard>
    <div class="form-group">
      <button type="button" class="btn btn-primary mr-2" @click="registrar()">
        <i class="fa fa-floppy-o" aria-hidden="true"></i>
        Guardar
      </button>
    </div>
    <!-- Tabla  modificar-->
    <CCard>
      <CCardBody>
        <div class="table-responsive">
          <table class="table" id="registros-table">
            <thead>
            <tr>

              <th scope="col">Etiqueta</th>

              <th scope="col">Acción</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="valores in registros" :key="valores.id">

              <td v-text="valores.etiqueta"></td>

              <td>
                <div class="btn-group">
                  <a @click="eliminar(valores.id)" class="btn btn-secondary btn-xs">
                    <i class="cil-trash"></i>
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>
    <!-- END: Tabla -->
  </div>
</template>
<script>
import httpService from "@/services/httpService"
import selectEtiqueta from '@/components/SelectEtiqueta.vue'


export default {
  components: {
    selectEtiqueta
  },
  props: {
    eco_producto_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,

      id: '',
      registro: {},
      registros: []
    }
  },
  methods: {

    setEtiqueta(value) {
      this.registro.eco_etiqueta_id = value
    },

    async eliminar(id) {
      let url = 'eco-productos-etiquetas/' + id
      let response = await httpService.delete(url)
      this.$vToastify.success('Operación realizada con éxito', 'Éxito')
      await this.listar();
    },

    async registrar() {
      try {

        this.registro.eco_producto_id = this.eco_producto_id
        this.registro.activo = 1
        let url ='eco-productos-etiquetas'
        let response = await httpService.post(url, this.registro)
        this.$vToastify.success('Operación realizada con éxito', 'Éxito')
        await this.listar()
      } catch (error) {
        console.log(error)
      }
    },

    async listar() {

      let url = 'eco-productos-etiquetas?criterio=eco_producto_id&buscar=' + this.eco_producto_id
      let response = await httpService.get(url)
      let respuesta = response.data
      this.registros = respuesta.registros.data
      this.pagination = respuesta.pagination
    }
  },
  created() {
    this.listar()
  }
}
</script>