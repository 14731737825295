import { render, staticRenderFns } from "./SelectEstadoProducto.vue?vue&type=template&id=548d831b&"
import script from "./SelectEstadoProducto.vue?vue&type=script&lang=js&"
export * from "./SelectEstadoProducto.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports