<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="3">
            <div class="form-group">
              <label>
                Modelo:
              </label>
              <validation-provider
                  name="Modelo"
                  :rules="{ required: true }"
                  :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                  v-slot="validationContext"
              >
                <input
                    type="text"
                    v-model="registro.modelo"
                    class="form-control"
                />
                <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
              </validation-provider>
            </div>
          </CCol>


          <CCol sm="3">
            <div class="form-group">
              <label>
                Sku:
              </label>
              <validation-provider
                  name="Sku"
                  :rules="{ required: true }"
                  :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                  v-slot="validationContext"
              >
                <input
                    type="text"
                    v-model="registro.sku"
                    class="form-control"
                />
                <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
              </validation-provider>
            </div>
          </CCol>

          <CCol sm="3">
            <div class="form-group">
              <label>
                Upc:
              </label>
              <validation-provider
                  name="Upc"
                  :rules="{ required: true }"
                  :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                  v-slot="validationContext"
              >
                <input
                    type="text"
                    v-model="registro.upc"
                    class="form-control"
                />
                <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
              </validation-provider>
            </div>
          </CCol>

          <CCol sm="3">
            <div class="form-group">
              <label>
                Ean:
              </label>
              <validation-provider
                  name="Ean"
                  :rules="{ required: true }"
                  :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                  v-slot="validationContext"
              >
                <input
                    type="text"
                    v-model="registro.ean"
                    class="form-control"
                />
                <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
              </validation-provider>
            </div>
          </CCol>
        </CRow>

        <CRow>
          <CCol sm="4">
            <div class="form-group">
              <label>
                Jan:
              </label>
              <validation-provider
                  name="Jan"
                  :rules="{ required: true }"
                  :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                  v-slot="validationContext"
              >
                <input
                    type="text"
                    v-model="registro.jan"
                    class="form-control"
                />
                <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
              </validation-provider>
            </div>
          </CCol>

          <CCol sm="4">
            <div class="form-group">
              <label>
                Isbn:
              </label>
              <validation-provider
                  name="Isbn"
                  :rules="{ required: true }"
                  :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                  v-slot="validationContext"
              >
                <input
                    type="text"
                    v-model="registro.isbn"
                    class="form-control"
                />
                <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
              </validation-provider>
            </div>
          </CCol>

          <CCol sm="4">
            <div class="form-group">
              <label>
                Mpn:
              </label>
              <validation-provider
                  name="Mpn"
                  :rules="{ required: true }"
                  :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                  v-slot="validationContext"
              >
                <input
                    type="text"
                    v-model="registro.mpn"
                    class="form-control"
                />
                <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
              </validation-provider>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="4">
            <div class="form-group">
              <label>
                Nivel stock:
              </label>
              <validation-provider
                  name="Nivel stock"
                  :rules="{ required: true }"
                  :customMessages="{
                  required: 'Es un campo requerido'
                }"
                  v-slot="validationContext"
              >
                <input type="number" v-model="registro.usar_nivel_stock" class="form-control"/>
                <span style="color: red; font-size: small">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </CCol>

          <CCol sm="4">
            <div class="form-group">
              <label>
                Stock:
              </label>
              <validation-provider
                  name="Stock"
                  :rules="{ required: true }"
                  :customMessages="{
                  required: 'Es un campo requerido'
                }"
                  v-slot="validationContext"
              >
                <input type="number" v-model="registro.stock" class="form-control"/>
                <span style="color: red; font-size: small">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </CCol>

          <CCol sm="4">
            <div class="form-group">
              <label>
                Advertencia nivel stock:
              </label>
              <validation-provider
                  name="Advertencia nivel stock"
                  :rules="{ required: true }"
                  :customMessages="{
                  required: 'Es un campo requerido'
                }"
                  v-slot="validationContext"
              >
                <input type="number" v-model="registro.advertencia_nivel_stock" class="form-control"/>
                <span style="color: red; font-size: small">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </CCol>
        </CRow>

      </CCardBody>
    </CCard>
    <div class="form-group">
      <button type="button" class="btn btn-primary mr-2" @click="registrar()">
        <i class="fa fa-floppy-o" aria-hidden="true"></i>
        Guardar
      </button>
    </div>
    <!-- Tabla  modificar-->
    <CCard>
      <CCardBody>
        <div class="table-responsive">
          <table class="table" id="registros-table">
            <thead>
            <tr>
              <th scope="col">Modelo</th>
              <th scope="col">Sku</th>
              <th scope="col">Upc</th>
              <th scope="col">Ean</th>
              <th scope="col">Jan</th>
              <th scope="col">Isbn</th>
              <th scope="col">Mpn</th>
              <th scope="col">Nivel stock</th>
              <th scope="col">Stock</th>
              <th scope="col">Advertencia stock</th>

              <th scope="col">Acción</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="valores in registros" :key="valores.id">

              <td v-text="valores.modelo"></td>
              <td v-text="valores.sku"></td>
              <td v-text="valores.upc"></td>
              <td v-text="valores.ean"></td>
              <td v-text="valores.jan"></td>
              <td v-text="valores.isbn"></td>
              <td v-text="valores.mpn"></td>
              <td v-text="valores.usar_nivel_stock"></td>
              <td v-text="valores.stock"></td>
              <td v-text="valores.advertencia_nivel_stock"></td>


              <td>
                <div class="btn-group">
                  <a @click="eliminar(valores.id)" class="btn btn-secondary btn-xs">
                    <i class="cil-trash"></i>
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>
    <!-- END: Tabla -->
  </div>
</template>
<script>
import httpService from "@/services/httpService"

export default {
  components: {},
  props: {
    eco_producto_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,

      id: '',
      registro: {},
      registros: []
    }
  },
  methods: {

    async eliminar(id) {
      let url = 'eco-productos-datos/' + id
      let response = await httpService.delete(url)
      this.$vToastify.success('Operación realizada con éxito', 'Éxito')
      this.listar();
    },

    async registrar() {
      try {

        this.registro.eco_producto_id = this.eco_producto_id
        let url ='eco-productos-datos'
        let response = await httpService.post(url, this.registro)
        this.$vToastify.success('Operación realizada con éxito', 'Éxito')
        await this.listar()
      } catch (error) {
        console.log(error)
      }
    },

    async listar() {

      let url = 'eco-productos-datos?criterio=eco_producto_id&buscar=' + this.eco_producto_id
      let response = await httpService.get(url)
      let respuesta = response.data
      this.registros = respuesta.registros.data
      this.pagination = respuesta.pagination
    }
  },
  created() {
    this.listar()
  }
}
</script>