<template>
  <div>
    <CCard>
      <CCardHeader>
        <h3 class="float-left">Editar Producto</h3>
      </CCardHeader>
      <CCardBody>
        <CTabs variant="pills">
          <CTab title="Datos basicos">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form class="contact-form" method="post" v-on:submit.prevent="handleSubmit(actualizar)">
                <CCard>
                  <CCardBody>
                    <CRow>
                      <CCol sm="4">
                        <div class="form-group">
                          <label>
                            Producto:
                          </label>
                          <validation-provider
                              name="Producto"
                              :rules="{ required: true }"
                              :customMessages="{
                              required: 'Es un campo requerido'
                            }"
                              v-slot="validationContext"
                          >
                            <input type="text" v-model="registro.producto" class="form-control"/>
                            <span style="color: red; font-size: small">
                              {{ validationContext.errors[0] }}
                            </span>
                          </validation-provider>
                        </div>
                      </CCol>

                      <CCol sm="4">
                        <div class="form-group">
                          <label>
                            Descripción corta:
                          </label>
                          <validation-provider
                              name="Descripción corta"
                              :rules="{ required: true }"
                              :customMessages="{
                              required: 'Es un campo requerido'
                            }"
                              v-slot="validationContext"
                          >
                            <input type="text" v-model="registro.descripcion_corta" class="form-control"/>
                            <span style="color: red; font-size: small">
                              {{ validationContext.errors[0] }}
                            </span>
                          </validation-provider>
                        </div>
                      </CCol>

                      <CCol sm="4">
                        <div class="form-group">
                          <label>
                            Descripción:
                          </label>
                          <validation-provider
                              name="Descripción"
                              :rules="{ required: true }"
                              :customMessages="{
                              required: 'Es un campo requerido'
                            }"
                              v-slot="validationContext"
                          >
                            <input type="text" v-model="registro.descripcion" class="form-control"/>
                            <span style="color: red; font-size: small">
                              {{ validationContext.errors[0] }}
                            </span>
                          </validation-provider>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol sm="4">
                        <div class="form-group">
                          <label>
                            Estado producto:
                          </label>
                          <validation-provider name="estado_producto" :rules="{ required: true }"
                                               :customMessages="{ required: 'Es un campo requerido' }"
                                               v-slot="validationContext">
                            <selectEstadoProducto :estadoId="estadoId" @ActualizandoId="setEstadoProducto"/>
                            <input type="hidden" v-model="registro.eco_producto_condicion_id"/>
                            <span style="color: red; font-size: small">
                              {{ validationContext.errors[0] }}
                            </span>
                          </validation-provider>
                        </div>
                      </CCol>

                      <CCol sm="4">
                        <div class="form-group">
                          <label>
                            Marca:
                          </label>
                          <validation-provider name="marca" :rules="{ required: true }"
                                               :customMessages="{ required: 'Es un campo requerido' }"
                                               v-slot="validationContext">
                            <selectMarca :marcaId="marcaId" @ActualizandoId="setMarca"/>
                            <input type="hidden" v-model="registro.eco_marca_id"/>
                            <span style="color: red; font-size: small">
                              {{ validationContext.errors[0] }}
                            </span>
                          </validation-provider>
                        </div>
                      </CCol>

                      <CCol sm="4">
                        <div class="form-group">
                          <label>
                            Idioma:
                          </label>
                          <validation-provider :rules="{ required: true }"
                              :customMessages="{ required: 'Es un campo requerido' }" v-slot="validationContext"
                          >
                            <selectIdioma :idiomaId="idiomaId" @ActualizandoId="setIdioma"/>
                            <input type="hidden" v-model="registro.eco_idioma_id"/>
                            <span style="color: red; font-size: small">
                              {{ validationContext.errors[0] }}
                            </span>
                          </validation-provider>
                        </div>
                      </CCol>

                      <CCol sm="4">
                        <div class="form-group row">
                          <label class="col-md-4 form-control-label" for="text-input">Imagen</label>
                          <div class="col-md-12" v-if="!registro.imagen_subida">
                            <img style="max-height: 100px;" :src="registro.imagen" alt=""/>
                            <input
                                name="inputFile"
                                @change="handleImage"
                                class="form-control"
                                type="file"
                                accept="image/png, image/jpeg, image/jpg"
                            />
                          </div>
                          <div class="col-md-9" v-if="registro.imagen_subida">
                            <img
                                style="max-height: 100px; width: 100px"
                                :src="urlImage + 'eco/small_'  + registro.imagen"
                                alt=""
                            />&nbsp;
                            <button
                                type="button"
                                class="btn btn-sm btn-danger"
                                @click="borrarImagen(registro.id, registro.imagen)"
                            >
                              <i class="cil-trash"></i>
                              Borrar
                            </button>
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>

                <div class="form-group">
                  <button type="submit" class="btn btn-primary">
                    <span class="cil-save btn-icon mr-2"></span>
                    Actualizar
                  </button>

                  <router-link to="/ecoProductos/List">
                    <CButton class="ml-2" color="secondary">
                      <span class="cil-x-circle btn-icon mr-2"></span>
                      Cancelar
                    </CButton>
                  </router-link>
                </div>
              </form>
            </ValidationObserver>
          </CTab>
          <CTab title="Atributos">
            <ecoProductosAtributos :eco_producto_id="id"></ecoProductosAtributos>
          </CTab>
          <CTab title="Categorias">
            <ecoProductosCategorias :eco_producto_id="id"></ecoProductosCategorias>
          </CTab>
          <CTab title="Datos">
            <ecoProductosDatos :eco_producto_id="id"></ecoProductosDatos>
          </CTab>
          <CTab title="Especificaciones">
            <ecoProductosEspecificaciones :eco_producto_id="id"></ecoProductosEspecificaciones>
          </CTab>
          <CTab title="Etiquetas">
            <ecoProductosEtiquetas :eco_producto_id="id"></ecoProductosEtiquetas>
          </CTab>
          <CTab title="Medidas">
            <ecoProductosMedidas :eco_producto_id="id"></ecoProductosMedidas>
          </CTab>
          <CTab title="Precios">
            <ecoProductosPrecios :eco_producto_id="id"></ecoProductosPrecios>
          </CTab>
          <CTab title="Imagenes">
            <ecoProductosImagenes :eco_producto_id="id"></ecoProductosImagenes>
          </CTab>
        </CTabs>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import httpService from "@/services/httpService"
import selectIdioma from '@/components/SelectIdioma.vue'
import selectEstadoProducto from '@/components/SelectEstadoProducto.vue'
import selectMarca from "@/components/SelectMarca.vue"
import ecoProductosAtributos from './components/ecoProductosAtributos.vue'
import ecoProductosCategorias from './components/ecoProductosCategorias.vue'
import ecoProductosDatos from './components/ecoProductosDatos.vue'
import ecoProductosMedidas from './components/ecoProductosMedidas.vue'
import ecoProductosPrecios from './components/ecoProductosPrecios.vue'
import ecoProductosEtiquetas from './components/ecoProductosEtiquetas.vue'
import ecoProductosEspecificaciones from './components/ecoProductosEspecificaciones.vue'
import ecoProductosImagenes from './components/ecoProductosImagenes.vue'

export default {
  components: {
    selectIdioma,
    selectEstadoProducto,
    selectMarca,
    ecoProductosAtributos,
    ecoProductosCategorias,
    ecoProductosDatos,
    ecoProductosMedidas,
    ecoProductosPrecios,
    ecoProductosEtiquetas,
    ecoProductosEspecificaciones,
    ecoProductosImagenes
  },
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      id: '',
      registro: {},
      estadoId: '',
      idiomaId: '',
      marcaId: 0
    }
  },

  methods: {
    async getRegistro() {
      if (this.id !== '') {
        const url ='eco-productos/' + this.id;
        let response = await httpService.get(url)
        let respuesta = response.data
        this.registro = respuesta.registros[0]
        this.estadoId = this.registro.eco_producto_condicion_id.toString()
        this.idiomaId = this.registro.eco_idioma_id.toString()
        this.marcaId = this.registro.eco_marca_id
        this.registro.imagen_subida = this.registro.imagen !== ''
      }
    },
    async actualizar() {
      try {
        const url ='eco-productos/' + this.id
        let response = await httpService.put(url, this.registro)
        this.$vToastify.success('Operación realizada con éxito', 'Éxito')
        this.$router.back()
      } catch (error) {
        console.log(error)
      }
    },

    handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      if (selectedImage.type === 'image/jpeg' || selectedImage.type === 'image/jpg' || selectedImage.type === 'image/png') {
        this.createBase64Image(selectedImage);
      } else {
        this.registro.imagen = 'invalid';
        Vue.$toast.success('Tipo de imagen no válida. Por favor, seleccione otro archivo.', {
          type: 'error',
          position: 'top-right'
        });
      }
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.registro.imagen = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },

    setIdioma(value) {
      this.registro.eco_idioma_id = value
    },
    setEstadoProducto(value) {
      this.registro.eco_producto_condicion_id = value
    },
    setMarca(value) {
      this.registro.eco_marca_id = value
    },
    async borrarImagen(id = '', imagen = '') {
      let url = "eco-productos/eliminar-imagen/" + id;
      await axios.delete(url);
      url ='eco-productos-imagenes/' + id + '&imagen=' + imagen;
      axios
          .get(url)
          .then(function (response) {
            this.registro.imagen = ''
            this.registro.imagen_subida = false
            this.$vToastify.success('Operación realizada con éxito', 'Éxito')
          })
          .catch(function (error) {
            console.log(error)
          })
    }
  },

  created() {
    this.id = this.$route.params.id
    this.getRegistro()
  }
}
</script>