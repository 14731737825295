<template>
  <div>
    <CCard>
      <CCardBody>

            <CRow>
          <CCol sm="12">
            <div class="form-group">
              <label>
                Tipo precio:
              </label>
              <validation-provider
                name="Tipo precio"
                :rules="{ required: true }"
                :customMessages="{
                  required: 'Es un campo requerido'
                }"
                v-slot="validationContext"
              >
                <input type="text" v-model="registro.tipo_precio" class="form-control" />
                <span style="color: red; font-size: small">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6">
            <div class="form-group">
              <label>
                Cantidad minima:
              </label>
              <validation-provider
                name="Cantidad minima"
                :rules="{ required: true }"
                :customMessages="{
                  required: 'Es un campo requerido'
                }"
                v-slot="validationContext"
              >
                <input type="number" v-model="registro.cantidad_minima" class="form-control" />
                <span style="color: red; font-size: small">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </CCol>

          <CCol sm="6">
            <div class="form-group">
              <label>
                Precio:
              </label>
              <validation-provider
                name="Precio"
                :rules="{ required: true }"
                :customMessages="{
                  required: 'Es un campo requerido'
                }"
                v-slot="validationContext"
              >
                <input type="number" v-model="registro.precio" class="form-control" />
                <span style="color: red; font-size: small">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </CCol>
        </CRow>


      </CCardBody>
    </CCard>
    <div class="form-group">
      <button type="button" class="btn btn-primary mr-2" @click="registrar()">
        <i class="fa fa-floppy-o" aria-hidden="true"></i>
        Guardar
      </button>
    </div>
    <!-- Tabla  modificar-->
    <CCard>
      <CCardBody>
        <div class="table-responsive">
          <table class="table" id="registros-table">
            <thead>
              <tr>
                <th scope="col">Tipo precio</th>
                <th scope="col">Cantidad minima</th>
                <th scope="col">Precio</th>



                <th scope="col">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="valores in registros" :key="valores.id">

                <td v-text="valores.tipo_precio"></td>
                <td v-text="valores.cantidad_minima"></td>
                <td v-text="valores.precio"></td>


                <td>
                  <div class="btn-group">
                    <a @click="eliminar(valores.id)" class="btn btn-secondary btn-xs">
                      <i class="cil-trash"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>
    <!-- END: Tabla -->
  </div>
</template>
<script>
import httpService from "@/services/httpService"
export default {
  components: {

  },
  props: {
    eco_producto_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,

      id: '',
      registro: {},
      registros: []
    }
  },
  methods: {

    async eliminar(id) {
      let url = 'eco-productos-precios/' + id
      let response = await httpService.delete(url)
      this.$vToastify.success('Operación realizada con éxito', 'Éxito')
      await this.listar();
    },

    async registrar() {
      try {

        this.registro.eco_producto_id = this.eco_producto_id
        this.registro.activo = 1
        let url ='eco-productos-precios'
        let response = await httpService.post(url, this.registro)
        this.$vToastify.success('Operación realizada con éxito', 'Éxito')
        await this.listar()
      } catch (error) {
        console.log(error)
      }
    },

    async listar() {

      let url = 'eco-productos-precios?criterio=eco_producto_id&buscar=' + this.eco_producto_id
      let response = await httpService.get(url)
      let respuesta = response.data
      this.registros = respuesta.registros.data
      this.pagination = respuesta.pagination
    }
  },
  created() {
    this.listar()
  }
}
</script>