<template>
  <div>

    <select class="form-control" name="Atributos " @change="onChangeValor()" v-model="atributo_idSelected">
      <option v-for="item in registros" :value="item.id" :key="item.id">
        {{ item.atributo }}
      </option>
    </select>

  </div>
</template>
<script>
import httpService from "@/services/httpService"

export default {
  props: {
    atributoId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      registro_id: '',
      atributo_idSelected: '',
      registros: [],
      isLoading: false
    };
  },
  watch: {
    /* Observar cambios en el valor del PROPS y cuando ya tenga busco los datos del registro */
    atributoId() {
      this.atributo_idSelected = this.atributoId
      return this.atributo_idSelected
    }
  },
  methods: {
    async getRegistros() {
      this.isLoading = true
      let url = "eco-atributos"
      let response = await httpService.get(url)
      let respuesta = response.data
      this.registros = respuesta.registros.data
      this.isLoading = false;
    },
    onChangeValor() {
      this.registro_id = this.atributo_idSelected
      this.$emit("ActualizandoId", this.atributo_idSelected)
    }
  },
  mounted() {
    this.$emit("ActualizandoId", this.registro_id)
    this.getRegistros();
  }
};
</script>