<template>
  <div>
    <CCard>
      <CCardBody>

        <CRow>
          <CCol sm="4">
            <div class="form-group">
              <label>
                Dimension ancho:
              </label>
              <validation-provider
                  name="Dimension ancho"
                  :rules="{ required: true }"
                  :customMessages="{
                  required: 'Es un campo requerido'
                }"
                  v-slot="validationContext"
              >
                <input type="number" v-model="registro.dimension_ancho" class="form-control"/>
                <span style="color: red; font-size: small">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </CCol>

          <CCol sm="4">
            <div class="form-group">
              <label>
                Dimension alta:
              </label>
              <validation-provider
                  name="Dimension alta"
                  :rules="{ required: true }"
                  :customMessages="{
                  required: 'Es un campo requerido'
                }"
                  v-slot="validationContext"
              >
                <input type="number" v-model="registro.dimension_alta" class="form-control"/>
                <span style="color: red; font-size: small">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </CCol>

          <CCol sm="4">
            <div class="form-group">
              <label>
                Dimension larga:
              </label>
              <validation-provider
                  name="Dimension larga"
                  :rules="{ required: true }"
                  :customMessages="{
                  required: 'Es un campo requerido'
                }"
                  v-slot="validationContext"
              >
                <input type="number" v-model="registro.dimension_largo" class="form-control"/>
                <span style="color: red; font-size: small">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="4">
            <div class="form-group">
              <label>
                Dimension unidad:
              </label>
              <validation-provider
                  name="Dimension unidad"
                  :rules="{ required: true }"
                  :customMessages="{
                  required: 'Es un campo requerido'
                }"
                  v-slot="validationContext"
              >
                <input type="number" v-model="registro.dimension_unidad" class="form-control"/>
                <span style="color: red; font-size: small">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </CCol>

          <CCol sm="4">
            <div class="form-group">
              <label>
                Peso:
              </label>
              <validation-provider
                  name="Peso"
                  :rules="{ required: true }"
                  :customMessages="{
                  required: 'Es un campo requerido'
                }"
                  v-slot="validationContext"
              >
                <input type="number" v-model="registro.peso" class="form-control"/>
                <span style="color: red; font-size: small">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </CCol>

          <CCol sm="4">
            <div class="form-group">
              <label>
                Peso unidad:
              </label>
              <validation-provider
                  name="Peso unidad"
                  :rules="{ required: true }"
                  :customMessages="{
                  required: 'Es un campo requerido'
                }"
                  v-slot="validationContext"
              >
                <input type="number" v-model="registro.peso_unidad" class="form-control"/>
                <span style="color: red; font-size: small">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </CCol>
        </CRow>


      </CCardBody>
    </CCard>
    <div class="form-group">
      <button type="button" class="btn btn-primary mr-2" @click="registrar()">
        <i class="fa fa-floppy-o" aria-hidden="true"></i>
        Guardar
      </button>
    </div>
    <!-- Tabla  modificar-->
    <CCard>
      <CCardBody>
        <div class="table-responsive">
          <table class="table" id="registros-table">
            <thead>
            <tr>
              <th scope="col">Dimension ancho</th>
              <th scope="col">Dimension alta</th>
              <th scope="col">Dimension larga</th>
              <th scope="col">Dimension unidad</th>
              <th scope="col">Peso</th>
              <th scope="col">Peso unidad</th>


              <th scope="col">Acción</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="valores in registros" :key="valores.id">

              <td v-text="valores.dimension_ancho"></td>
              <td v-text="valores.dimension_alta"></td>
              <td v-text="valores.dimension_largo"></td>
              <td v-text="valores.dimension_unidad"></td>
              <td v-text="valores.peso"></td>
              <td v-text="valores.peso_unidad"></td>

              <td>
                <div class="btn-group">
                  <a @click="eliminar(valores.id)" class="btn btn-secondary btn-xs">
                    <i class="cil-trash"></i>
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>
    <!-- END: Tabla -->
  </div>
</template>
<script>
import httpService from "@/services/httpService"

export default {
  components: {},
  props: {
    eco_producto_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,

      id: '',
      registro: {},
      registros: []
    }
  },
  methods: {

    async eliminar(id) {
      let url = 'eco-productos-medidas/' + id
      let response = await httpService.delete(url)
      this.$vToastify.success('Operación realizada con éxito', 'Éxito')
      await this.listar();
    },

    async registrar() {
      try {

        this.registro.eco_producto_id = this.eco_producto_id
        this.registro.activo = 1
        let url ='eco-productos-medidas'
        let response = await httpService.post(url, this.registro)
        this.$vToastify.success('Operación realizada con éxito', 'Éxito')
        await this.listar()
      } catch (error) {
        console.log(error)
      }
    },

    async listar() {

      let url = 'eco-productos-medidas?criterio=eco_producto_id&buscar=' + this.eco_producto_id
      let response = await httpService.get(url)
      let respuesta = response.data
      this.registros = respuesta.registros.data
      this.pagination = respuesta.pagination
    }
  },
  created() {
    this.listar()
  }
}
</script>