<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <div class="form-group row">
              <label class="col-md-4 form-control-label" for="text-input">Imagen</label>
              <div class="col-md-12" v-if="!registro.imagen_subida">
                <img style="max-height: 100px;" :src="registro.imagen" alt=""/>
                <input
                    name="inputFile"
                    @change="handleImage"
                    class="form-control"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                />
              </div>
              <div class="col-md-9" v-if="registro.imagen_subida">
                <img style="max-height: 100px; width: 100px" :src="urlImage + 'eco/small_' + registro.imagen" alt=""/>
                <button type="button" class="btn btn-sm btn-danger" @click="borrarImagen(registro.id, registro.imagen)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                  Borrar
                </button>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <div class="form-group">
      <button type="button" class="btn btn-primary mr-2" @click="registrar()">
        <i class="fa fa-floppy-o" aria-hidden="true"></i>
        Guardar
      </button>
    </div>
    <!-- Tabla  modificar-->
    <CCard>
      <CCardBody>
        <div class="table-responsive">
          <table class="table" id="registros-table">
            <thead>
            <tr>

              <th scope="col">Imagen</th>

              <th scope="col">Acción</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="valores in registros" :key="valores.id">

              <td>
                <div v-if="valores.imagen != null">
                  <img
                      style="max-height: 100px; width: 100px"
                      :src="urlImage + 'eco/small_'  + valores.imagen"
                      alt=""
                  />
                </div>
              </td>
              <td>
                <div class="btn-group">
                  <a @click="eliminar(valores.id)" class="btn btn-secondary btn-xs">
                    <i class="cil-trash"></i>
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>
    <!-- END: Tabla -->

  </div>
</template>
<script>
import httpService from "@/services/httpService"

export default {
  components: {},
  props: {
    eco_producto_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      id: '',
      registro: {
        eco_producto_id: 0,
        orden: 0,
        descripcion: '',
        imagen: null,
        imagen_subida: false
      },
      registros: []
    }
  },
  methods: {
    async listar() {

      let url = 'eco-productos-imagenes?criterio=eco_producto_id&buscar=' + this.eco_producto_id
      let response = await httpService.get(url)
      let respuesta = response.data
      this.registros = respuesta.registros.data
      this.pagination = respuesta.pagination
    },

    cambiarPagina(page, buscar = '', criterio = '') {

      //Actualiza la página actual
      this.pagination.current_page = page
      //Envia la petición para visualizar la data de esa página
      this.listar(page, buscar, criterio)
    },

    async eliminar(id) {
      let url = 'eco-productos-imagenes/' + id
      let response = await httpService.delete(url)
      this.$vToastify.success('Operación realizada con éxito')
      await this.listar()
    },

    async registrar() {
      try {

        this.registro.eco_producto_id = this.eco_producto_id
        this.registro.activo = 1
        let url ='eco-productos-imagenes'
        let response = await httpService.post(url, this.registro)

        this.$vToastify.success('Operación realizada con éxito', 'Éxito')
        await this.listar()
      } catch (error) {
        console.log(error)
      }
    },

    handleImage(e) {
      const selectedImage = e.target.files[0] // get first file
      if (
          selectedImage.type === 'image/jpeg' ||
          selectedImage.type === 'image/jpg' ||
          selectedImage.type === 'image/png'
      ) {
        this.createBase64Image(selectedImage)
      } else {
        this.registro.imagen = 'invalid'
        Vue.$toast.success('Tipo de imagen no válida. Por favor, seleccione otro archivo.', {
          type: 'error',
          position: 'top-right'
        })
      }
    },
    createBase64Image(fileObject) {
      const reader = new FileReader()
      reader.onload = e => {
        this.registro.imagen = e.target.result
      }
      reader.readAsDataURL(fileObject)
    },
    borrarImagen(id = '', imagen = '') {
      let url = 'eco-productos-imagenes/?id=' + id + '&imagen=' + imagen

      axios
          .get(url)
          .then(function (response) {
            let respuesta = response.data
            this.registro.imagen = ''
            this.registro.imagen_subida = false
            // this.cerrarModal();
            this.listar()
            this.$vToastify.success('Operación realizada con éxito', 'Éxito')
          })
          .catch(function (error) {
            console.log(error)
          })
    }
  },
  created() {
    this.listar()
  }
}
</script>